




















import Vue from "vue";
import userManager from "@/services/userManager";

export default Vue.extend({
  data() {
    return {
      item: null,
      currentTab: "",
      tabs: {
        balances: {
          label: "Tổng quan báo sạc",
          condition() {
            return userManager.checkRole(["kt", "ksnb", "ksnbl"]);
          },
        },
        bills: {
          label: "Hóa đơn",
          condition() {
            return userManager.checkRole(["kt", "ksnb", "ksnbl", "mkt", "tp"]);
          },
        },
        accounts: {
          label: "Tài khoản quảng cáo",
          condition() {
            return userManager.checkRole(["kt", "ksnb", "ksnbl", "mkt", "tp"]);
          },
        },
        mkt_users: {
          label: "MKT Users",
          condition() {
            return userManager.checkRole(["kt", "ksnb", "ksnbl", "mkt", "tp"]);
          },
        },
      },
    };
  },
  async created() {},
});
